<template>
  <div class="home-download">
    <div class="layout-bg"></div>
    <div class="max-width relative">
      <div class="py-10 apps" >
        
        <p class="home-download__text font-35 font-500 white--text">
          {{ $t("downloadApp") }}
        </p>

        <div class="d-flex">
          <v-btn class="c-btn" color="white" elevation="0" height="42" max-width="210px" min-width="150px">
            <img
              width="17px"
              class="mx-2"
              :src="require('@/assets/images/global/google.png')"
              alt=""
            />
            <span class="font-20 font-500 primary--text">Google Play</span>
          </v-btn>
          <v-btn class="c-btn mx-3" color="white" elevation="0" height="42" max-width="210px" min-width="150px">
            <img
              width="17px"
              class="mx-2"
              :src="require('@/assets/images/home/apple.svg')"
              alt=""
            />
            <span class="font-20 font-500 primary--text">App Store</span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.home-download {
  background: url("../../../assets/images/home/download-bg.png") center center
    no-repeat;
  background-size: cover;
  margin-top: 100px;
  position: relative;
  margin: auto;
  height: 400px;

  &__text {
    max-width: 500px;
  }
.apps{
  height: 400px;
  display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-around;
}
  .layout-bg {
    background: linear-gradient(
      102.06deg,
      #1f4d5c 63.55%,
      rgba(137, 187, 203, 0) 99.66%
    );
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0.4;
  }
}
</style>
