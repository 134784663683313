<template>
  <div class="home-services ">
    <div class="max-width">
      <div class="home-services__head">
        <span class="font-50 font-700 primary--text">{{
          $t("ourServices")
        }}</span>
        <!-- <span class="font-50 font-700 primary--text">{{
          $t("serviceTitle")
        }}</span> -->
        <!-- <p class="font-25 black--text mt-2">
          {{ $t("serviceDesc") }}
        </p> -->
      </div>

      <v-row class="mt-5 text-center">
        <v-col class="px-14" v-for="(service, i) in services" :key="i">
          <div class="home-services__service">
            <div class="home-services__service__img text-center mx-auto">
              <img
                class="object-cover mx-auto"
                max-width="200px"
                :src="service.img"
                alt=""
              />
            </div>

            <div class="mt-5">
              <span class="primary--text font-30 font-500">{{
                service.title
              }}</span>
              <p class="font-20 font-300 grey--text mt-3">
                {{ service.desc }}
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  data: (vm) => ({
    services: [
      {
        title: vm.$t("homepage.services[0].title"),
        desc: vm.$t("homepage.services[0].desc"),
        img: require("@/assets/images/home/service_1.svg"),
      },
      {
        title: vm.$t("homepage.services[1].title"),
        desc: vm.$t("homepage.services[1].desc"),
        img: require("@/assets/images/home/service_2.svg"),
      },
      {
        title: vm.$t("homepage.services[2].title"),
        desc: vm.$t("homepage.services[2].desc"),
        img: require("@/assets/images/home/service_3.svg"),
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.home-services {
  margin: 5% 0px;
  &__head {
    margin: auto 4%;
  }

  &__service {
    &__img {
      width: 225px;
      height: 225px;
      border-radius: 50%;
      background: #E5F1F4;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      max-width: 350px;
      margin: auto;
    }
  }
}
</style>
