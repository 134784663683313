<template>
  <div class="home-types">
    <div class="max-width">
      <div class="mb-14 pb-5" v-for="(type, i) in types" :key="i">
        <!-- title -->
        <div class="">
          <div class="home-types__type mb-4 d-flex align-center">
            <img width="30px" class="mx-2" :src="type.icon" />
            <span class="font-35 font-700 primary--text">{{ type.title }}</span>
          </div>
        </div>

        <!-- list -->
        <v-row align="center" :class="type.reverse ? 'row-reverse' : ''">
          <v-col cols="12" md="6">
            <div
              class="home-types__item white py-5 px-5 my-7"
              v-for="(item, j) in type.items"
              :key="j"
            >
              <span class="font-25 primary--text font-500"
                >{{ j + 1 }}- {{ item.title }}</span
              >
              <p class="font-300 font-20 black--text mb-0 cursor-pointer">
                {{ item.desc }}
                <span class="lightblue--text" @click="$router.push('/about')"
                  >{{$t('Readmore')}}...</span
                >
              </p>
            </div>
          </v-col>
          <v-col cols="12" md="6" class="text-center">
            <v-img class="mx-auto image-home" :src="type.img" alt="" />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: (vm) => ({
    types: [
      {
        title: vm.$t("forSenders"),
        icon: require("@/assets/images/icons/senders.svg"),
        img: require("@/assets/images/home/senders.png"),
        items: vm.$t("homepage.senders"),
        reverse: false,
      },
      {
        title: vm.$t("forTravlers"),
        icon: require("@/assets/images/icons/travelers.svg"),
        img: require("@/assets/images/home/travelers.png"),
        items: vm.$t("homepage.travelers"),
        reverse: true,
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.home-types {
  &__type {
    position: relative;
  }

  &__item {
    border-radius: 10px;
    box-shadow: 0px 4px 20px 0px #00000017;
  }
}
</style>
