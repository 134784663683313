<template>
  <div class="home-websites px-2">
    <img
      class="home-websites__bg object-contain"
      :src="require('@/assets/images/home/websites-bg.png')"
      alt=""
    />
    <div class="max-width ">
      <span class="font-40 font-500 primary--text"
        >{{ $t("recomendedWebsites") }}</span
      >
      <p class="lightGreyText--text font-25 mx-2">
        {{ $t("homepage.recommendWebsitesDesc") }}
      </p>
    </div>
    <div class="home-websites__items">
      <slick-carousel v-bind="settings">
        <div cols="12" md="4" class="px-8" v-for="(item, i) in items" :key="i">
          <div class="home-websites__items__item text-center mb-12 dark-shadow">
            <a :href="item.url" target="_black">
              <img
                width="200px"
                height="60px"
                class="object-contain d-block mx-auto"
                :src="item.icon"
                alt=""
              />
            </a>
            <!-- <div>

              <span class="font-20 black--text font-500 d-block mt-2">{{item.name}}</span>
            </div> -->
          </div>
        </div>
      </slick-carousel>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    settings: {
      arrows: false,
      dots: true,
      focusOnSelect: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    },
  }),
};
</script>

<style lang="scss" scoped>
.home-websites {
  padding-top: 3%;
  margin-bottom: 4%;
  position: relative;
  &__bg {
    position: absolute;
    right: 0px;
    top: 30px;
  }
  .max-width {
    max-width: 1200px;
    padding: 0px 3%;
  }

  &__items {
    max-width: 1200px;
    margin: auto;
    margin-top: 2%;
    padding: 0px 2%;

    &__item {
      border-radius: 16px;
      padding: 20px 0px;
      background: white;
      box-shadow: 0px 4px 20px 0px #00000017;

    }
  }
}
</style>
