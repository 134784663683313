<template>
  <div class="home-products ">
    <!-- <img
      class="home-products__bg object-contain"
      :src="require('@/assets/images/home/product-bg.png')"
      alt=""
    /> -->
    <div class="max-width ">
      <span class="font-40 font-500 primary--text">{{
        $t("recomendedProducts")
      }}</span>

      <p class="lightGreyText--text font-25 mx-2">
        {{ $t("homepage.recommendProductsDesc") }}
      </p>
    </div>

    <div class="my-10 home-products__items">
      <slick-carousel class="my-7" v-bind="settings">
        <div
          class="px-10 mr-5 mb-14"
          v-for="(item, i) in items"
          :key="i"
          
        >
          <div class="product-card cursor-pointer" @click.prevent="$router.push('/product-order/' + item.id)">
            <img class="object-contain mx-auto pt-5" height="150px" width="90%" :src="item.image" alt="">
            <div class="pa-3 d-flex justify-space-between align-center ">
              <span class="d-block grey--text ">{{item.title}}</span>
              <span class="d-block black--text font-28 font-500">{{item.price}}$</span>
            </div>
            <v-divider class="mx-1"></v-divider>

            <div v-if="item.website" class="product-card__website">
              <img class="object-contain mx-auto " height="42px" width="140px" :src="item.website[0].icon" alt="">
            </div>
          </div>

            <!-- :route="'/product-details/' + item.id" -->
        </div>
      </slick-carousel>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    settings: {
      arrows: false,
      dots: true,
      focusOnSelect: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    },
  }),
};
</script>

<style lang="scss" scoped>
.home-products {

  padding-top: 3%;
  position: relative;
  &__bg {
    position: absolute;
    left: 0px;
    top: 0px;
  }
  .max-width {
    max-width: 1200px;
    padding: 0px 3%;
  }

  &__items {
    max-width: 1250px;
    margin: auto;
    padding: 0px 2%;

    &__item {
      border-radius: 16px;
      border: 1px solid #c2c2c2;
      padding: 30px 0px;
    }
  }
  .product-card {
    position: relative;
    border-radius: 10px;
    background: white;
    box-shadow: 0px 4px 20px 0px #00000017;

    overflow: hidden;
    transition: 0.3s;

  }
}
</style>
