<template>
  <div class="homepage" >
    <home-header></home-header>
    <user-types></user-types>
    <services></services>
    <!-- <home-cards></home-cards> -->
    <websites v-if="data.Websites.length > 0" :items="data.Websites"></websites>
    <products v-if="data.products.length > 0" :items="data.products"></products>
    <download></download>
  </div>
</template>

<script>
import HomeHeader from "./components/Header";
import UserTypes from "./components/UserTypes";
// import HomeCards from "./components/Cards";
import Websites from "./components/Websites";
import Products from "./components/Products";
import Download from "./components/Download";
import Services from "./components/Services";
export default {
  components: {
    HomeHeader,
    UserTypes,
    // HomeCards,
    Websites,
    Products,
    Download,
    Services,
  },
  data: () => ({
    data: {Websites: [], products: []},
  }),
  methods: {
    async getData() {
      let { data } = await this.$axios.get("/home");
      if (data.data) {
        this.data = data.data;

        this.data.products = [
          ...data.data.products.map((item) => {
            return {
              id: item.id,
              image: item.image,
              images: [],
              title: item.title,
              price: item.price,
              website: item.website,
            };
          }),
        ];
      }
    },
  },
  
  async created() {
    await this.getData();
  },
};
</script>

<style lang="scss">
.image-home{
width: 450px;
}
@media (max-width: 500px) {
  .image-home{
   width: 300px;
}
}
</style>
