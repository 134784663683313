<template>
  <div class="home-header px-2">
    <div class="max-width">
      <v-row align="center">
        <v-col cols="12" md="6" class="mt-3">
          <div class="contant_header">
            <div>
              <span class="font-60 primary--text font-500 line-50"
                >{{ $t("homepage.shopAnywhere") }}
              </span>
            </div>
            <div>
              <p class="font-25 grey--text ">
                {{ $t("homepage.headTitle") }}
              </p>
            </div>
            <div>
              <p class="font-25 grey--text ">
                {{ $t("homepage.secondTitle") }}
              </p>
            </div>
            <div>
              <v-row class="" justify="space-between">
                <v-col cols="12" md="6" sm="6" class="">
                  <v-btn
                    class="c-btn "
                    color="primary"
                    width="100%"
                    height="40"
                    @click="$router.push('/add-shipment')"
                    ><span class="white--text font-300 font-20">{{
                      $t("deliverForMe")
                    }}</span></v-btn
                  >
                </v-col>
                <v-col cols="12" md="6" sm="6" >
                  <v-btn
                    class="c-btn "
                    color="primary"
                    width="100%"
                    height="40"
                    @click="$router.push('/buy-product/offline')"
                    ><span class="white--text font-300 font-20">{{
                      $t("buyForMe")
                    }}</span></v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="text-center">
          <v-img
            class="mx-auto image-home"

            :src="require('@/assets/images/home/header-img.png')"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>

.home-header {
  margin-top: 5%;
  margin-bottom: 4%;

  &__play {
    height: 300px;
  }

  .contant_header{
    height: 450px;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-around;
  }

}
</style>
